import React from 'react'
 import '../css/contact.css'
 function Contact() {

  return (
    <div  class="p-3   h-100 " id="contact">
      <div class=" contact g-5 row  d-flex justify-content-center align-items-center mx-2 my-5">
        <div class=" col-md-10 col-sm-12 col-lg-8  rounded-5 bg-white shadow-lg p-3">
        <div class="ms-1 my-4 "> 
      <p  class=" fs-1 text-black">Feel Free to Contact Us </p>
        </div>
        <form class="row g-4  p-0"  role="form" action="https://formsubmit.co/abc@gmail.com" method="POST" onsubmit="true" >
  <div class="col-md-12 col-lg-6">
    <input type="text" class="form-control  border-black rounded-5 cont"  name="name" placeholder='Name'  required/>  
  </div>
  <div class="col-md-12 col-lg-6">
    <input type="text" class="form-control border-black rounded-5 cont"  name="name" placeholder='Phone'  required/>  
  </div>
  <div class="col-md-12 col-lg-6 ">
    <input type="email" class="form-control border-black rounded-5 cont"  name="email"  placeholder='E-mail'  required/>
  </div>
  <div class="col-md-12 col-lg-6"> 
    <input type="text" class="form-control border-black rounded-5 cont " name="subject"  placeholder='subject' required/> 
  </div>
  <div class="col-md-12 "> 
    <textarea class = "form-control border-black rounded-5"  name="message" placeholder='Message'rows = "8"></textarea>  
  </div>
  <input type="hidden" name="_next" value="https://theologyinstitute.dbclc.com/contact"/>
  <div class="col-12 ">
    <button class="btn btn-outline-primary btn-lg my-3" type="submit" >Send Message</button>
  </div>
       </form>
        </div>
    <div class=" col-md-10 col-sm-12 col-lg-8 bg-white  d-flex  align-items-center rounded-5 ">
<div class=" m-3 bg-white rounded-3 ">
      <p class="text-dark fs-6"> <i class="  fa-solid fa-location-dot text-primary "></i>   DBCLC, Institute of Theology, East Fort, Thrissur, 680005
     </p>
     
        <div>
          <p  class=" fs-6   text-black "> Call Us </p>
          <p  class=" fs-6   text-black ">  <i class="  fa-solid fa-phone text-primary "></i>   +91 487 2322520</p>
          <p  class=" fs-6   text-black ">  <i class="  fa-solid fa-phone text-primary "></i>   +91 9495106870</p>
          <p  class=" fs-6   text-black ">  <i class="  fa-solid fa-phone text-primary "></i>   +91 9744941585</p>

          {/* <p  class=" fs-5   font-monospace text-black ">  <i class="  fa-solid fa-phone text-primary "></i> +91 ------------</p> */}
         </div>
         <div>
          <p  class=" fs-6  text-black "> Write to Us</p>
          {/* <p  class=" fs-5  text-black "><i class="  fa-solid fa-envelope text-primary "></i>    excelacademytcr@gmail.com</p> */}
         </div>
         
</div>
    </div >    
    <div class=" col-md-10 col-sm-12 col-lg-8 bg-white  d-flex  align-items-center rounded-5">
<div class=" m-3 bg-white rounded-3">
<p class="text-dark fs-6"> <i class="  fa-solid fa-location-dot text-primary "></i>    Civil Service Campus, Avinissery, Ollur, Thrissur, 680306
  </p>
        <div>
          <p  class=" fs-6   text-black "> Call Us </p>
          {/* <p  class=" fs-5   text-black ">  <i class="  fa-solid fa-phone text-dark "></i> +91 1234567890</p> */}
          <p  class=" fs-6   text-black ">  <i class="  fa-solid fa-phone text-primary "></i>    +91 487 2351333</p>
         </div>
         <div>
          <p  class=" fs-6  text-black "> Write to Us</p>
          {/* <p  class=" fs-5  text-black "><i class="  fa-solid fa-envelope text-primary "></i>    excelresidentcampus@gmail.com</p> */}
         </div>
         
</div>
    </div >  
     </div>
    </div>
  )
}
export default Contact;
// no-border 