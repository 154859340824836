import React from 'react'
import'../css/footer.css'
 function Footer() {
  return (
    <div class="px-3 bg-light" id="footer"> 
    <div>
      <div class="d-flex justify-content-start align-items-center">
    <img src="/assets/logos/Theology-2.png" alt="  university" width="220" height="250" class=" m-3  "/>
      </div>
    <div class=" row my-5 mx-4" >
  <div class="d-flex justify-content-start col-md-4 col-sm-12 col-lg-3  p-2 ">
<div class="">
<h3 class="text-dark "> Institute of Theology </h3>
  <p class="text-dark fs-6 " >The mission of the Institute of Theology at Trichur is to give theological and pastoral formation for the laity,
   the religious and the catechists, especially of the Archdiocese .
   </p>
  <p class="text-dark fs-6"> <i class="  fa-solid fa-location-dot text-dark "></i>
  DBCLC,Institute of Theology, East Fort, Thrissur
    </p>   
    <p class="text-dark fs-6"> <i class="  fa-solid fa-location-dot text-dark "></i>
    Civil Service Campus, Avinissery, Ollur, Thrissur
    </p> 
    {/* <p class="text-dark fs-6"> <i class="  fa-solid fa-clock text-dark "></i>
    Monday - Saturday at 9 AM - 6 PM
    </p>  */}
     
</div>
  </div>
  <div class=" d-flex justify-content-start col-md-4 col-sm-12  col-lg-3 p-2 ">
  <div class=" ">
  <h3 class="text-dark "> Page links..</h3>
  {/* <div><a class="  fs-6 text-dark text-decoration-none " href="#Services"> ..Courses </a></div> */}
  {/* <div><a class=" fs-6 text-dark text-decoration-none" href="#team"> ..Team </a></div> */}
  <div><a class="  fs-6 text-dark text-decoration-none " href="#"> ..Home </a></div>
  <div><a class="  fs-6 text-dark text-decoration-none " href="#"> ..About </a></div>
  <div><a class="  fs-6 text-dark text-decoration-none " href="#"> ..Contact</a> </div>
</div>

  </div>
  <div class="d-flex justify-content-start  col-md-4  col-sm-12 col-lg-3 p-2 ">
  <div class="">
  <h3 class="text-dark "> Contacts</h3>
  <p class="text-dark fs-6"> <i class="  fa-solid fa-location-dot text-dark "></i>
  DBCLC,Institute of Theology, East Fort, Thrissur
  </p>
  <p class="text-dark fs-6"> <i class="  fa-solid fa-location-dot text-dark "></i>
  Civil Service Campus, Avinissery, Ollur, Thrissur
  </p>
  <p class="text-dark fs-6"> <i class="  fa-solid fa-calendar-days text-dark "></i> Monday - Saturday at 9 AM - 6 PM </p>
  {/* <p class="text-dark fs-6">  <i class="  fa-solid fa-envelope text-dark "></i> excelacademytcr@gmail.com , excelresidentcampus@gmail.com </p>  */}
  <p class="text-dark fs-6">  <i class="  fa-solid fa-phone text-dark "></i> +91 487 2322520,+91 9495106870, <br/> +91 9744941585 </p>
  <p class="text-dark fs-6">  <i class="  fa-solid fa-phone text-dark "></i> +91 487 2351333</p>   
</div> 
  </div>
 
</div>
<div class=" row bg-black p-0 ">
<div class=" col-md-6  col-lg-7 d-flex align-items-center justify-content-center ">
      
      <div  class=" fs-6   text-white text-center">© Copyright 2023  Designed By Apex Data Technologies All Rights Reserved . </div>
    </div>
    <div class="col-md-6 col-lg-5 d-flex align-items-center justify-content-center " > 
    <a class=" m-2 text-decoration-none px-2" href="/"> <i class="  fa-brands fa-facebook  fa-2x"></i>  </a>
    <a class=" m-2 text-decoration-none px-2" href="/"> <i class="  fa-brands fa-twitter  fa-2x"></i>  </a>
    <a class=" m-2 text-decoration-none px-2" href="/"> <i class="  fa-brands fa-instagram  fa-2x"></i>  </a>
    <a class=" m-2 text-decoration-none px-2" href="/"> <i class="  fa-brands fa-linkedin fa-2x"></i>  </a>
    <a class=" m-2 text-decoration-none px-2" href="/"> <i class="  fa-brands fa-youtube fa-2x"></i>  </a>
    <a class=" m-2 text-decoration-none px-2" href="/"> <i class="  fa-brands fa-pinterest   fa-2x"></i>  </a>
    <a class=" m-2 text-decoration-none px-2" href="/"> <i class="  fa-brands fa-whatsapp  fa-2x"></i>  </a>
    <a class=" m-2 text-decoration-none px-2" href="/"> <i class="  fa-brands fa-telegram fa-2x"></i>  </a>
   </div>
 
  </div>
      </div>       
    </div>
  )
}
export default Footer;