import React from 'react';
import CarouselComponent from'../custom components/CarouselComponent';
import CustomCard from '../custom components/CustomCard'
import CoursesCard from '../custom components/CoursesCard';
import Courses from './Courses';
import Registration from './Registration';
import'../css/Home.css';
export default function Home() {
  return (
    <div class="p-1">
    <CarouselComponent/>
    <div  class="  p-2 mt-5" id="containerinfo">    
         <div class="p-3 "  data-aos="fade-up"  data-aos-easing="ease-out-cubic"
     data-aos-duration="1500">
      <div class=" row">
        <div class=" col-sm-12 col-md-12 col-lg-6   d-flex  justify-content-center align-items-center" >
          
          <img src="/assets/images/holy-rosary.jpg" class=" img img-fluid w-100  h-100" alt="test image" id="aboutimg" />   
                   
        </div>
        <div class=" col-sm-12 col-md-12 col-lg-6 p-3  d-flex  justify-content-center align-items-center" >
          <div class="me-3 what">       
          <p  class=" fs-1 fw-bold text-dark ">
          What We Offer
         </p>
         <p  class=" fs-5  text-primary ">
         Institute of Theology offers you  the opportunity to focus on the Christian faith in detail through the study of the Bible, the history of Christianity, its critical thinkers and its influence on ethical debates and the actions of its believers.
         </p>
         <p  class=" fs-5  text-dark ">
         {/* We are committed to helping students see possibilities beyond what they experience every day by empowering young students to achieve their aspirations of cracking entrance exams of medical and engineering, civil service exams and other job oriented competitive exams. Excel Academy marks its unique mission in identifying poor but intelligent students and equipping them for higher education with full or partial scholarships. */}
         Studying Theology and Religious Studies will allow you to analyse writing, concepts and arguments in various contexts. The disciplines demand rigorous thinking and the ability to interpret data concisely. Also, you will learn to debate key concepts and evaluate their relevance and validity with other students. Respect for the beliefs of others and the skill to enter into dialogue with those who hold opposing views are developed in the academic environment of the School of Divinity.
         </p>
         <a class="btn btn-outline-primary btn-lg text-decoration-none  "href="/">Join Us </a> 
         </div>
        </div>
      </div>
    </div>    
  </div>
   
  {/* <div  class="p-2">
  <div class="  row " id=" cards-container">
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>

  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
  <div class=" col-sm-12 col-md-6 col-lg-3 d-flex justify-content-center p-3">
  <CustomCard img="/assets/dumy.png" title="uwduyww" subject="nwiufbewiufwfeiwbfiwufbwiuf"/>
  </div>
   </div>
</div> */}
</div>
  )
}
